export const environment = {
  production: false,
  azureAd: {
    tenant: 'chevron.onmicrosoft.com',
    clientId: 'b4d58c38-7b63-405a-90b8-1a9fb8bdceb1',
    postLogoutRedirectUri: 'https://llfem-dev.azure.chevron.com/logout',
    authority: 'https://login.microsoftonline.com/fd799da1-bfc1-4234-a91c-72b3a1cb9e26',
    redirectUri: 'https://llfem-dev.azure.chevron.com',
    graphApis: {
      users: 'https://graph.microsoft.com/v1.0/users',
      me: 'https://graph.microsoft.com/v1.0/me'
    },
    endpoints: {
      'https://chevron.onmicrosoft.com/fd799da1-bfc1-4234-a91c-72b3a1cb9e26'
        : 'https://chevron.onmicrosoft.com/fd799da1-bfc1-4234-a91c-72b3a1cb9e26'
    },
    scope:{
      basePath:'https://llfem-api-dev-cvx.azurewebsites.net',
      userImpersonation :'https://llfem-test.azure.chevron.com/user_impersonation',
    }
  },
  appCode: 'webApp',
  apiEndpoint: 'https://llfem-api-dev-cvx.azurewebsites.net/api'
};
